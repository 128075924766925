@import './reset';
@import './normalize';

@import './mixins';
@import './variables';

:root {
  @include defineBaseColorVariables($colors);
  @include defineTintColorVariables($colors);
  @include defineShadeColorVariables($colors);
  @include defineZIndexVariables();
}

@include defineCustomFontFamily('OpenSans', 800);

:root {
  --body-background-color: #fff;
  --max-content-width: 1680px; //1480px - orginal
  --content-margin: 23px;

  --spacing-lg: 6.25rem;
  --spacing-md: 3.75rem;
  --spacing-sm: 2.8125rem;

  @media #{$size-md} {
    --spacing-lg: 7.5rem;
    --spacing-md: 4.6875rem;
    --spacing-sm: 3.4375rem;
  }

  @media #{$size-lg} {
    --spacing-lg: 10rem;
    --spacing-md: 5.625rem;
    --spacing-sm: 4.375rem;
  }

  font-size: 16px;
  line-height: 1.2;
}

body {
  font-family: var(--font-family);
  background-color: var(--body-background-color);
  color: var(--text-color);

  &.assistant-status-expanded-large,
  &.assistant-status-expanded-small {
    @media #{$size-sm-and-below} {
      overflow: hidden;
    }
  }
}

a {
  color: var(--link-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: var(--bold-font-weight);
  margin: 0;
}

h1 {
  font-size: 1.5rem;
}

h2 {
  font-size: 1.2rem;
}

h3,
h4,
h5,
h6 {
  font-size: 1rem;
}

@media #{$size-md} {
  h1 {
    font-size: 1.8rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.2rem;
  }

  h4,
  h5,
  h6 {
    font-size: 1rem;
  }
}

@media #{$size-lg} {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.8rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  h4 {
    font-size: 1.2rem;
  }

  h5,
  h6 {
    font-size: 1rem;
  }
}

.container {
  --max-content-width: 1400px;//1114


  // margin-inline: auto;
}


label.textfield {
  display: block;
  margin-top: 14px;
  font-size: 14px;
  font-weight: bold;

  input,
  textarea {
    display: block;
    margin-top: 3px;
    width: 100%;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    padding: 5px 10px;
    &::placeholder {
      color: #D9D9D9;
    }
  }

  input {
    height: 35px;
  }

  textarea {
    min-height: 120px;
  }
  &.error {
    input {
      border-color: var(--warning-color);
    }
  }
}
label.checkbox {
  display: flex;
  margin-top: 14px;
  font-size: 14px;
  font-weight: bold;
  gap: 10px;

  input {
    appearance: none;
    border: 1px solid #676767;
    border-radius: 3px;
    height: 20px;
    width: 20px;

    &:checked {
      position: relative;
      border-color: #1579fb;
      background-color: #1579fb;
      &::after {
        content: "✔";
        position: absolute;
        top: -2px;
        left: 2px;
        color: white;
        font-size: 18px;
      }
    }
  }

  &.error {
    input {
      border-color: var(--warning-color);
    }
  }
}

.apply-validation-style.ng-submitted {
  input[type=text],
  input[type=password] {
    &.ng-invalid {
      border-color: var(--warning-color);
    }
  }
}

.white-text{
  color: #fff;
}

.hidden-on-phone{
  display: none;
}

.table {
  display: flex;
  //justify-content: space-between;
  flex-direction: column;
  border: 1px solid #656565;
  height: 25rem;
  padding: 10px;
  border-radius: 10px;
  position: relative;
  overflow-y: auto;
  .tr {
      border-bottom: 1px solid #DDDDDD;
      margin: 8px 0;
      padding: 10px 0 20px 0;
      width: 60rem;
      display: grid;
      grid-template-columns: 2fr 0.8fr 0.8fr 0.8fr 0.8fr;
      grid-template-rows: 1fr;
      gap: 0px 0px;
  }.ts {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 10px;

  }.td {
      font-size: 14px;
      margin-top: auto;
      margin-bottom: auto;
  }
}

.top-tr {
  display: grid;
    grid-template-columns: 2fr 0.8fr 0.8fr 0.8fr 0.8fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    background-color: #f2f2f2;
    padding: 10px;
    position: sticky;
    top: 0;
    width: 100%;
    box-sizing: border-box;
    font-weight: bold;
    width: 60rem;
}

.top-tr p {
  font-size: 12px !important;
}


.idden-on-phone{
  display: none !important;
}
//popup
.dashbord-h5{
  color: #002d72;
}
.popup-wrap-all-inner{
  margin: 10px;
}
.pop-ups-p {
  padding: 1rem 0;
  font-size: 13px;
}

.circle-number-text-wrap{
  display: flex;
  align-items: center;
}

.circle-number{
  background-color: #002d72;
  color: #fff;
  margin-right: 10px;
  padding: 7px 11px;
  border-radius: 16px;
}

.widget-percent-change{
  padding: 6px;
  border-radius: 12px;
  width: 120px;
  text-align: end;
}

.list-info{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.mgleft{
  margin-left: auto;
}

.section-index-6 > sm-container > div:first-child{
  border-top: 1px solid #656565;
}

@media only screen and (min-width: 540px) {
  .popup-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  /*.table .tr {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }*/

  .ts{
    margin-top: 0px !important;
  }

  /*.top-tr{
    display: grid;
    grid-template-columns: 2fr 0.8fr 0.8fr 0.8fr 0.8fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    background-color: #f2f2f2;
    padding: 10px;
    position: sticky;
    top: 0;
    width: 100%;
    box-sizing: border-box;
    font-weight: bold;
  }*/

}

@media only screen and (min-width: 768px) {
  .table {
    height: 32rem;
  }
}

@media only screen and (min-width: 1180px) {
  .table {
    height: 38rem;
  }
}

@media only screen and (min-width: 1385px) {
  .table {
    height: 38rem;
  }

  .table .tr {
    width: 67rem;
  }

  .top-tr{
    width: 67rem;
  }
}

@media only screen and (min-width: 1525px) {
  .table {
    height: 36rem;
  }
}
